import { createSlice } from "@reduxjs/toolkit"

const shipmentSlice = createSlice({
  name: "shipment",
  initialState: {
    shipmentForDuplicate: null,
    showSavedLocations: null,
    stopsToSave: [],
    saveStartStop: false,
    saveEndStop: false,
  },
  reducers: {
    setShipmentForDuplicate(state, action) {
      state.shipmentForDuplicate = action.payload
    },
    setShowSavedLocations(state, action) {
      state.showSavedLocations = action.payload
    },
    setStopsToSave(state, action) {
      state.stopsToSave = action.payload
    },
    setSaveStartStop(state, action) {
      state.saveStartStop = action.payload
    },
    setSaveEndStop(state, action) {
      state.saveEndStop = action.payload
    },
  },
  extraReducers: {},
})

const shipmentReducer = shipmentSlice.reducer
export { shipmentReducer }
export const {
  setShipmentForDuplicate,
  setShowSavedLocations,
  setSaveStartStop,
  setSaveEndStop,
  setStopsToSave,
} = shipmentSlice.actions
