import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { setFlash, removeFlash } from "../../state/flashSlice"
import {
  FLASH_INFO,
  FLASH_SUCCESS,
  FLASH_WARNING,
  FLASH_ERROR,
} from "../constants/flashTypes"

type OpenFlashPayload = {
  message: string
  type?:
    | typeof FLASH_INFO
    | typeof FLASH_WARNING
    | typeof FLASH_ERROR
    | typeof FLASH_SUCCESS
  timeout?: number
}

export default function useFlash() {
  const flashSelector = useSelector((state: RootStateOrAny) => state.flash)
  const dispatch = useDispatch()

  const openFlash = ({
    message,
    type = FLASH_INFO,
    timeout = 4000,
  }: OpenFlashPayload) => {
    dispatch(setFlash({ message, type }))
    setTimeout(() => dispatch(removeFlash()), timeout)
  }

  return { ...flashSelector, openFlash }
}
